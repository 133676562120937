<template>
  <div class="projectContentContainier">
    <!-- <div v-html="article"></div> -->
    <iframe
      :src="contentUrl"
      frameborder="0"
      class="content"
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    ></iframe>
  </div>
</template>
<script setup>
// import article from '@/articles/Kumu Lectra Sand Clock/1922/index.html';
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
const Store = useStore();

const router = useRouter();
const route = useRoute();
const goBack = () => {
  router.go(-1);
};

const id = route.params.id;
const project = Store.state.picWallImgList.filter((item) => item.id === id)[0];

let contentUrl = project.h5_url;
</script>
<style lang="scss">
$dark-color: rgba(20, 26, 34, 0.9);
div.projectContentContainier {
  flex: 3;
  //background: $dark-color;
  background: transparent;
}
.content {
  width: 100%;
  height: 100%;
}
</style>