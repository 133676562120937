<template>
  <div class="projectIntroductionContainier">
    <div class="nav">
      <el-button
        :icon="ArrowLeft"
        class="backbtn"
        circle
        size="large"
        @click="goBack"
      />
    </div>
    <div class="cover">
      <img :src="project.img_url" />
    </div>

    <h3 class="title">{{ project.title }}</h3>
    <p class="about">
      <span class="time">{{ project.time }}</span>
      <span class="author">{{ project.author }} 上传</span>
    </p>
    <p class="about">
      收录于<span class="collectedAt">{{ project.collectedAt }}</span>
    </p>
    <el-row class="tag">
      <el-tag
        class="mx-1"
        size="large"
        effect="plain"
        v-for="city in project.city"
        :key="city"
      >
        {{ city }}
      </el-tag>
      <el-tag v-if="project.type" class="mx-1" size="large" effect="plain">{{
        project.type
      }}</el-tag>
    </el-row>
    <!-- 暂不显示描述 -->
    <!-- <p class="description">
      {{ project.excerpt }}
    </p> -->
  </div>
</template>
<script setup>
import { ArrowLeft } from "@element-plus/icons-vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
// import  appinstance  from "./NewEarth/instance.js";
import DataLoader from "../data-interface/data-loader";

// console.log('appInstance',appInstance);
//

const router = useRouter();
const Store = useStore();
const route = useRoute();
const goBack = () => {
  const app = window.app;
  app.needAnimating = true;
  // if(app.needAnimating) app.animate();
  router.go(-1);
  // Store.commit("startAnimation");
};

const id = route.params.id;

const project = DataLoader.projects.filter((item) => item.id === id)[0];
</script>
<style scoped lang="scss">
$theme-color: #15def8;
$dark-color: rgba(20, 26, 34, 0.9);
$secondary-color: #10abbf;

div.projectIntroductionContainier {
  flex: 1;
  padding: 20px 40px;
  // background-color: #eee;
  //background-color: #efefef;

  box-sizing: border-box;
  .nav {
    margin: 30px 0;
    .backbtn {
      font-weight: bold;
      background: transparent;
      color: $secondary-color;
      border-color: $secondary-color;
      font-size: 18px;
    }
  }

  .cover {
    margin-top: 20px;
    width: 200px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .title {
    font-size: 28px;
    font-weight: 900;
    color: #ccc;
    margin: 10px 0;
  }

  .about {
    color: #ccc;
    font-size: 16px;
    line-height: 1.5em;
    margin-top: 10px;

    .author {
      margin-left: 10px;
      font-weight: bold;
    }

    .collectedAt {
      font-weight: bold;
      margin-left: 10px;
    }
  }

  .tag {
    margin-top: 30px;

    .el-tag--plain {
      margin-right: 10px;
      margin-bottom: 6px;
      background-color: transparent;
      color: $secondary-color;
      border-color: $secondary-color;
      font-size: 16px;
    }
  }

  .description {
    color: #ccc;
    font-size: 16px;
    line-height: 1.5em;
    margin-top: 1.5em;
    // 暂不显示描述
    // display: none;
  }
}
</style>